import React from "react";
import { BGDrawer, LogoMain } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import { CiMenuFries } from "react-icons/ci";
import { IoLogoInstagram } from "react-icons/io5";
import { LiaTripadvisor } from "react-icons/lia";
import { IoCloseCircle } from "react-icons/io5";
import { Drawer } from "antd";
import useViewPort from "../../hooks/useViewPort";
import { BsWhatsapp } from "react-icons/bs";

export default function Header({scrollPosition}) {
  const [open, setOpen] = React.useState(false);

  const isMobile = useViewPort();
  const [items, setItems] = React.useState([
    { txt: "Home", isActive: true, link: "/", iscroll: false },
    { txt: "Tour", isActive: false, link: "/tour", iscroll: false },
    { txt: "Our Story", isActive: false, link: "/about", iscroll: true },
    { txt: "News", isActive: false, link: "/blog", iscroll: false },
    { txt: "Contact", isActive: false, link: "/contact", iscroll: false },
  ]);

  const [itemActive, setItemActive] = React.useState();
  const location = useLocation();

  React.useEffect(() => {
    if (itemActive?.iscroll) {
      setTimeout(() => {
        let element = document.getElementById(itemActive?.link);
        if (element) {
          document.documentElement.scroll({
            top: element.offsetTop,
            behavior: "auto",
          });
        }
      }, 400);
    }
  }, [itemActive, location]);

  return (
    <div className={`wrapHeader ${isMobile.offsets.width < 992 ? "scrollHeaderMobile": ""}`} style={{position: (isMobile.offsets.width < 992 && scrollPosition > 150) ? "fixed": "initial"}}>
      <div className="maxh">
        <div className="tabbar">
          <img src={LogoMain} alt="Logo Ha Giang Loop Tour" />
          {isMobile.offsets.width > 992 ? (
            <ul className="menus">
              {items.map((i, k) => (
                <li
                  key={k}
                  onClick={() => {
                    const news = [...items];

                    const index = news.findIndex((o) => o.txt === i.txt);
                    news[index].isActive = true;

                    news.forEach((n) => {
                      if (n.txt !== i.txt) {
                        n.isActive = false;
                      }
                    });
                    setItems(news);
                  }}
                >
                  <Link
                    to={i.iscroll ? "/" : i.link}
                    className={`${
                      location.pathname === i.link ? "active" : ""
                    }`}
                    onClick={() => setItemActive(i)}
                  >
                    {i.txt}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="icons-tab-right">
          <ul>
            {/* <li>
              <PiShoppingCartThin />
              <div className="circle-sm-tag">
                <p>9+</p>
              </div>
            </li> */}
            <li>
              <CiMenuFries onClick={() => setOpen(true)} />
            </li>
          </ul>
        </div>
      </div>
      <>
        <Drawer
          placement="right"
          onClose={() => setOpen(false)}
          open={open}
          style={{ background: `url(${BGDrawer})` }}
        >
          <>
            <div className="drawer-drag-right-menu">
              <div className="drawer-drag-rm-content">
                <div className="drawer-drag-content-logo">
                  <img src={LogoMain} alt="Logo Ha Giang Loop Tour" />
                </div>
                <p>
                  Sun Flower provides travel services, Ha Giang Loop Tour,
                  motorbike trips, for customers who want to experience
                  motorbike driving or easy rider tour for those who cannot
                  drive a motorbike themselves, we are always committed to
                  quality, reliability with the best price.
                </p>

                {isMobile.offsets.width < 992 ? (
                  <ul className="menus">
                    {items.map((i, k) => (
                      <li
                        key={k}
                        onClick={() => {
                          const news = [...items];

                          const index = news.findIndex((o) => o.txt === i.txt);
                          news[index].isActive = true;

                          news.forEach((n) => {
                            if (n.txt !== i.txt) {
                              n.isActive = false;
                            }
                          });
                          setItems(news);
                        }}
                      >
                        <Link
                          to={i.iscroll ? "/" : i.link}
                          className={`${
                            location.pathname === i.link ? "active" : ""
                          }`}
                          onClick={() => {
                            setItemActive(i);
                            setOpen(false);
                          }}
                        >
                          {i.txt}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
                <ul className="infos">
                  <li>
                    <h6>Address</h6>
                    <p>No 86 Dai Lo Huu Nghi, Ha Giang City, Viet Nam</p>
                  </li>
                  <li>
                    <h6>Phone Number</h6>
                    <p>(+84) 3272 58338</p>
                  </li>
                  <li>
                    <h6>Email</h6>
                    <p>info.hgmotorbiketours@gmail.com</p>
                  </li>
                </ul>
                <ul className="ic-circle-infos">
                  <li>
                    <a
                      href="https://www.instagram.com/hgtours_hagiangloop?igsh=d2ppZnIxbmxxM3Nr&utm_source=qr"
                      alt="link-ins"
                      target="_blank"
                      rel="noreferrer"
                      itemProp="url"
                    >
                      <IoLogoInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://wa.me/0327258338?text=Welcome to SunFlower Tours Hotline!`}
                      alt="link-whatsapp"
                      target="_blank"
                      rel="noreferrer"
                      itemProp="url"
                    >
                      <BsWhatsapp />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      alt="link-tripadvisor"
                      itemProp="url"
                      href="https://www.tripadvisor.com/Attraction_Review-g1544599-d27742983-Reviews-Ha_Giang_Motorbike_Loop-Ha_Giang_Ha_Giang_Province.html"
                    >
                      <LiaTripadvisor />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="moveic">
                <IoCloseCircle onClick={() => setOpen(false)} />
              </div>
            </div>
          </>
        </Drawer>
      </>
    </div>
  );
}
