import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

export default function BannerFiForPage({ title }) {
  const navigate = useNavigate();
  return (
    <div className="wrap-bannerfiforpage">
      <img
        src={
          "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/files%2Fb2.jpg?alt=media&token=0d0316a1-bfc7-4ab8-8851-87e466d4a2db"
        }
        alt="Background Title For Each Page"
      />

      <div className="wrap-bannerfiforpage-content">
        <h1>{title}</h1>
        <p>
          <span onClick={() => navigate("/", { replace: true })}>Home</span>{" "}
          {">"} {title}
        </p>
      </div>
    </div>
  );
}
